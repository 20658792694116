<template>
  <div>
    <el-table :data="data" :height="height" class="zy-table" :span-method="objectSpanMethod" :header-cell-style="headerCellStyle" :header-row-style="headerRowStyle" :cell-style="cellStyle" :row-style="rowStyle">
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    height: Number,
    objectSpanMethod: Function,
  },
  methods: {
    headerCellStyle() {
      return {
        background: '#1E5087',
        borderBottom: 'unset',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#FFFFFF',
        lineHeight: '21px',
        padding: '12px 0 14px 0'
      }
    },
    headerRowStyle() {
      return {
        background: '#1E5087'
      }
    },
    rowStyle() {
      return {
        background: 'transparent'
      }
    },
    cellStyle() {
      return {
        fontSize: '14px',
        color: '#FFFFFF',
        lineHeight: '21px',
        padding: '10px',
        background: 'transparent',
        borderBottom: 'rgba(255, 255, 255, 0.1) 1px solid'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.zy-table {
  background: transparent !important;
  width: 100%;
  /deep/ .el-table__cell{
    padding: 10px 0!important;
    &>.cell{
      line-height: 19px;    
    }
  }
  /deep/ th.el-table__cell{
    height: 40px;
    padding: 9px 0!important;
  }
  /deep/ .el-table__cell.gutter{
    background: #215186;
  }
}
</style>
